import { useState, useEffect } from 'react'
import {
  Chip,
  Divider,
  Grid,
  Heading,
  List,
  // ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
// import { AnnouncementForm } from './AnnouncementForm'
import {
  getAdmin,
  // updateAdmin,
} from '../../../../service/dlmService'

// TODO: Remove this once date util functions are moved to DLM common
export const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date)
}

const date_options = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour12: true,
  hour: 'numeric',
  minute: 'numeric',
}

export const formatDate = (dateString) => {
  const date = new Date(dateString)
  if (isValidDate(date)) {
    return date.toLocaleString('en-US', date_options)
  }
  return ''
}

export const processAnnouncementMessage = (message) => {
  if (message) {
    const tokens = message.split(' ')

    for (let i = 0; i < tokens.length; i++) {
      if (tokens[i].startsWith('https://')) {
        const token = tokens[i]
        tokens[i] =
          `<a style="color: revert;text-decoration: revert;" target="_blank" href="${token}">${token}</a>`
      }
    }

    return tokens.join(' ')
  }

  return ''
}

export const Announcement = () => {
  // const makeToast = ToastProvider.useToaster()

  const [announcement, setAnnouncement] = useState({
    message: '',
    message_type: '',
    message_start: '',
    message_end: '',
  })

  useEffect(() => {
    getAdmin().then((data) => setAnnouncement(data))
  }, [])

  // const onUpdate = (values) => {
  //   updateAdmin(values)
  //     .then(() => {
  //       makeToast({
  //         type: 'success',
  //         heading: 'Success',
  //         message: 'Changes successfully saved',
  //       })
  //     })
  //     .catch((error) => {
  //       console.error(error)
  //       makeToast({
  //         type: 'error',
  //         heading: 'Server Error',
  //         message: 'Error saving changes',
  //       })
  //     })
  //     .then(() => {
  //       window.location.reload()
  //     })
  // }

  return (
    <Grid.Container sm={12}>
      <Grid.Item xs={12}>
        The Announcement Banner can be set to display at the top of the Domestic
        Load Manager app and will be visible to all users. Announcements will go
        away once the time is up or a new announcement can replace it.
      </Grid.Item>

      {/* <Grid.Item xs={12}>
        <AnnouncementForm onUpdate={onUpdate} />
      </Grid.Item> */}

      {announcement.message ? (
        <Grid.Item xs={12}>
          <Grid.Container>
            <Grid.Item xs={12}>
              <Heading size={5}>Current Announcement</Heading>
            </Grid.Item>

            <Grid.Item xs={7}>
              <div
                dangerouslySetInnerHTML={{
                  __html: processAnnouncementMessage(announcement.message),
                }}
              />
            </Grid.Item>

            <Divider width={2} direction="vertical" />

            <Grid.Item xs={4}>
              <List size="dense">
                <List.Item>
                  <Chip>{announcement.message_type?.toUpperCase()}</Chip>
                </List.Item>

                <List.Item>
                  <Grid.Container spacing="none">
                    <Grid.Item xs={6}>
                      <Grid.Container direction="column" spacing="none">
                        <Grid.Item>
                          <p className="hc-fs-xs">Message Start</p>
                        </Grid.Item>

                        <Grid.Item>
                          {formatDate(announcement.message_start)}
                        </Grid.Item>
                      </Grid.Container>
                    </Grid.Item>

                    <Grid.Item xs={6}>
                      <Grid.Container direction="column" spacing="none">
                        <Grid.Item>
                          <p className="hc-fs-xs">Message End</p>
                        </Grid.Item>

                        <Grid.Item>
                          {formatDate(announcement.message_end)}
                        </Grid.Item>
                      </Grid.Container>
                    </Grid.Item>
                  </Grid.Container>
                </List.Item>
              </List>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      ) : (
        <></>
      )}
    </Grid.Container>
  )
}
